<template>
  <dl class="divide-y divide-gray-200 mb-6">
    <div class="grid grid-cols-3 gap-4 py-4">
      <dt class="text-gray-500">Entrada</dt>
      <dd class="col-span-2">{{ downPaymentText }}</dd>
    </div>
    <div class="grid grid-cols-3 gap-4 py-4">
      <dt class="text-gray-500">
        Parcelas
      </dt>
      <dd class="col-span-2">
        {{ installmentText }}
      </dd>
    </div>
    <div class="grid grid-cols-3 gap-4 py-4">
      <dt class="text-gray-500">
        Valor total
      </dt>
      <dd class="col-span-2">
        {{ chosenPaymentOption.totalAmount | moneyFilter }}
      </dd>
    </div>
    <div class="grid grid-cols-3 gap-4 py-4">
    </div>
  </dl>
</template>

<script>
import stringMixin from '../../../mixins/string';

export default {
  name: 'payment-link-chosen-payment-option',
  mixins: [stringMixin],
  props: {
    chosenPaymentOption: {
      type: Object,
      required: true,
    },
  },
  computed: {
    downPaymentText() {
      const { downPaymentPercentage, downPaymentValue } = this.chosenPaymentOption;
      if (downPaymentPercentage === 0) return 'Sem entrada';
      return `${this.moneyFilter(downPaymentValue)} (${downPaymentPercentage}%)`;
    },
    installmentText() {
      const { numberOfInstallments, paymentInstallments } = this.chosenPaymentOption;
      return `${numberOfInstallments}x de ${this.moneyFilter(paymentInstallments)}`;
    },
  },
};
</script>
