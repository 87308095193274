<template>
  <div class="input-group">
    <div class="input-control">
      <textarea
        id="textarea-input"
        v-if="type === 'textarea'"
        v-bind:class="[errorMessage ? 'input-field invalid-input' : 'input-field']"
        style="min-height:96px;"
        :placeholder="placeholder"
        v-model="model"
        :maxLength="maxLength"
      ></textarea>

      <template v-else-if="type === 'cash'">
        <div class="input-content-left absolute left-3 top-1/2 transform -translate-y-1/2">
          R$
        </div>
        <money
          id="money-input"
          v-bind:class="[errorMessage ? 'input-field pl-9 invalid-input' : 'input-field pl-9']"
          v-model="model"
          v-bind="money"
        />
      </template>

      <input
        id="default-input"
        v-else
        v-bind:class="[errorMessage ? 'input-field invalid-input' : 'input-field']"
        :type="type"
        :placeholder="placeholder"
        v-model="model"
        :maxLength="maxLength"
      />
    </div>

    <p v-if="errorMessage" class="error-message text-red-700">{{ errorMessage }}</p>
  </div>
</template>

<script>
import { Money } from 'v-money';

export default {
  name: 'payment-link-input',
  props: {
    value: {},
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
    },
    maxLength: {
      type: Number,
    },
  },
  data: () => ({
    model: '',
    money: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false,
    },
  }),
  watch: {
    model(newValue) {
      this.$emit('input', newValue);
    },
  },
  components: {
    Money,
  },
  mounted() {
    this.model = this.value;
  },
};
</script>

<style scoped>
.invalid-input {
  border: 1px solid #EF4444;
  background-color: #FEF2F2;
}

.error-message {
  font-size: 14px;
  line-height: 20px;
  margin-top: 6px;
}
</style>
