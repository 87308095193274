<!-- eslint-disable max-len -->
<template>
  <div
    class="drawer"
    :class="paymentLink ? 'translate-x-0' : 'translate-x-full'"
  >
    <div class="py-6 overflow-x-auto flex-grow space-y-9">
      <header class="drawer-header flex items-center justify-between">
        <h3 class="uppercase tracking-widest text-sm font-semibold">
          Cancelar pedido
        </h3>
      </header>
      <div class="drawer-section" v-if="paymentLink">
        <div>
          <div class="flex justify-between">
            <label class="form-label">Motivo do cancelamento</label>
            <span class="text-gray-500 text-sm">
              {{ cancelReason.length }}/{{ formInputs.cancelReason.extraValidateFunctionParams.max }} caracteres
            </span>
          </div>
          <PaymentLinkInput
            type="textarea"
            v-model="cancelReason"
            @input="handleFormInput('cancelReason', ...arguments)"
            :errorMessage="getMessage('cancelReason')"
            :maxLength="formInputs.cancelReason.extraValidateFunctionParams.max"
            placeholder="Descreva brevemente o motivo para cancelar o pedido"
          />
        </div>
      </div>
    </div>
    <footer v-if="paymentLink" class="drawer-footer flex gap-3 justify-end">
      <button class="button" @click="closeCancelTransactionPage">
        <span>Voltar</span>
      </button>

      <button class="button button-danger-subtle" @click="cancelPurchase">
        <span>Confirmar cancelamento</span>
      </button>
    </footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PaymentLinkInput from './PaymentLinkInput.vue';

export default {
  name: 'payment-link-cancel-transaction',
  props: {
    paymentLink: {
      type: Object,
    },
  },
  components: {
    PaymentLinkInput,
  },
  data: () => ({
    cancelReason: '',
    formInputs: {
      cancelReason: {
        isValid: false,
        validateFunction: 'isBetweenMinAndMaxLength',
        extraValidateFunctionParams: {
          min: 1,
          max: 200,
        },
        errorMessage: 'Preencha a descrição do pedido',
        currentMessage: '',
      },
    },
  }),
  methods: {
    ...mapActions('paymentLink', ['cancelTransaction']),
    ...mapActions('loading', ['setIsLoading']),
    closeCancelTransactionPage() {
      this.$emit('closeCancelTransactionPage');
    },
    async cancelPurchase() {
      if (!this.isValidForm()) {
        this.handleErrorMessages();
        return;
      }

      try {
        this.setIsLoading({ isLoading: true });
        await this.cancelTransaction({
          paymentLink: this.paymentLink,
          retailerCancelReason: this.cancelReason,
        });
        this.$emit('closeCancelTransactionPage');
      } catch (error) {
        console.error(error);
      }
      this.setIsLoading({ isLoading: false });
    },
    isBetweenMinAndMaxLength(value, { min, max }) {
      return value && value.length >= min && value.length <= max;
    },
    isValidForm() {
      let isValid = true;
      const keys = Object.keys(this.formInputs);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (!this.formInputs[key].isValid) {
          isValid = false;
          break;
        }
      }
      return isValid;
    },
    handleFormInput(field, value) {
      const { validateFunction, extraValidateFunctionParams } = this.formInputs[field];
      this.formInputs[field].isValid = this[validateFunction](value, extraValidateFunctionParams);
      if (this.formInputs[field].currentMessage !== '') {
        this.formInputs[field].currentMessage = '';
      }
    },
    handleErrorMessages() {
      Object.keys(this.formInputs).forEach((key) => {
        if (!this.formInputs[key].isValid) {
          this.formInputs[key].currentMessage = this.formInputs[key].errorMessage;
        }
      });
    },
    getMessage(field) {
      return this.formInputs[field].currentMessage;
    },
  },
};
</script>
