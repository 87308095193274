<!-- eslint-disable max-len -->
<template>
  <div
    v-show="show"
    class="w-80 p-3 absolute left-0 top-full z-20 shadow-xl bg-white rounded-xl border-gray-300 border"
    style="min-width: 9rem;"
    id="payment-link-popover"
    v-click-outside="onClickOutside"
  >
    <div class="text-sm font-semibold mb-1.5">N° do pedido</div>
    <div class="mb-3">
      <input
        v-model="model"
        type="text"
        class="input-field"
        :class="{ 'bg-red-50 border-red-500': error }"
      />
      <p v-if="error" class="mt-1.5 text-sm text-red-500">Preencha o número do pedido</p>
    </div>
    <div class="flex justify-end gap-3">
      <button class="button button-sm button-primary" @click="onSave">Salvar</button>
    </div>
    <div id="payment-link-popover-arrow" data-popper-arrow></div>
  </div>
</template>

<script>
export default {
  name: 'payment-link-popover',
  props: {
    orderId: {
      type: String,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    model: null,
    error: false,
  }),
  watch: {
    orderId(newValue) {
      this.model = newValue;
    },
    show(newValue) {
      if (newValue) this.model = this.orderId;
    },
    model(newValue) {
      if (newValue) this.error = false;
    },
  },
  methods: {
    onSave() {
      if (this.model) {
        this.$emit('save', this.model);
      } else {
        this.error = true;
      }
    },
    onClickOutside(event) {
      this.$emit('clickOutside', event);
    },
  },
};
</script>

<style scoped lang="postcss">
  #payment-link-popover-arrow,
  #payment-link-popover-arrow::before {
    position: absolute;
    width: 14px;
    height: 14px;
    background: inherit;
  }

  #payment-link-popover-arrow {
    visibility: hidden;
  }

  #payment-link-popover-arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  #payment-link-popover[data-popper-placement^='top'] > #payment-link-popover-arrow {
    bottom: -8px;
  }

  #payment-link-popover[data-popper-placement^='top'] > #payment-link-popover-arrow::before {
    border: 1px solid rgba(203, 213, 225, var(--tw-border-opacity));
    border-left: 0;
    border-top: 0;
    margin-top: 0.8px;
  }

  #payment-link-popover[data-popper-placement^='bottom'] > #payment-link-popover-arrow {
    top: -8px;
  }

  #payment-link-popover[data-popper-placement^='bottom'] > #payment-link-popover-arrow::before {
    border: 1px solid rgba(203, 213, 225, var(--tw-border-opacity));
    border-right: 0;
    border-bottom: 0;
    margin-bottom: 1px;
  }

  #payment-link-popover[data-popper-placement^='left'] > #payment-link-popover-arrow {
    right: -8px;
  }

  #payment-link-popover[data-popper-placement^='right'] > #payment-link-popover-arrow {
    left: -8px;
  }
</style>
