<template>
  <div>
    <div
      class="fixed top-0 left-0 bottom-0 right-0 bg-black transition-all duration-500 z-30"
      :class="!paymentLink ? 'invisible opacity-0' : 'visible opacity-60'"
    ></div>
    <PaymentLinkCancelTransaction v-if="showCancelTransactionPage"
      :paymentLink="paymentLink"
      @closeCancelTransactionPage="closeCancelTransactionPage"
    />
    <PaymentLinkSummary v-else
      :paymentLink="paymentLink"
      @onHideDrawer="hideDrawer"
      @goToCancelTransactionPage="goToCancelTransactionPage"
    />
  </div>
</template>

<script>
import PaymentLinkSummary from './PaymentLinkSummary.vue';
import PaymentLinkCancelTransaction from './PaymentLinkCancelTransaction.vue';

export default {
  name: 'payment-link-drawer',
  props: {
    paymentLink: {
      type: Object,
    },
  },
  components: {
    PaymentLinkSummary,
    PaymentLinkCancelTransaction,
  },
  data: () => ({
    showCancelTransactionPage: false,
  }),
  methods: {
    hideDrawer() {
      this.$emit('onHideDrawer');
    },
    goToCancelTransactionPage() {
      this.showCancelTransactionPage = true;
    },
    closeCancelTransactionPage() {
      this.showCancelTransactionPage = false;
      this.$emit('onHideDrawer');
    },
  },
};
</script>
