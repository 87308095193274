<!-- eslint-disable max-len -->
<template>
  <div class="drawer" :class="paymentLink ? 'translate-x-0' : 'translate-x-full'">
    <div class="py-6 overflow-x-auto flex-grow" v-if="isDrawerLoading">
      <div class="p-6">
        <div class="space-y-5 animate-pulse">
          <div class="h-8 bg-gray-300 rounded-full w-48 mb-9"></div>
          <div class="h-6 bg-gray-300 rounded-full w-3/4"></div>
          <div class="h-6 bg-gray-300 rounded-full w-1/2"></div>
          <div class="h-6 bg-gray-300 rounded-full w-3/4"></div>
          <div class="h-6 bg-gray-300 rounded-full w-1/2"></div>
        </div>
      </div>
    </div>

    <template v-else>
      <div class="py-6 overflow-x-auto flex-grow">
        <header class="drawer-header">
          <div class="flex items-center justify-between">
            <h3 class="uppercase tracking-widest text-sm font-semibold">Link de pagamento</h3>
            <button
              aria-label="Close"
              class="button button-plain button-icon hover:border-gray-200 -mr-3"
              @click="hideDrawer"
            >
              <XIcon size="24" />
              <span class="sr-only"> Fechar </span>
            </button>
          </div>
        </header>
        <div v-if="paymentLink">
          <div class="relative" v-if="tabs.length > 1">
            <div class="flex gap-5 mt-2 pl-6">
              <button
                v-for="tab in tabs"
                :key="tab.value"
                class="text-gray-500 hover:text-primary-700 py-3 z-10"
                :class="{
                  'text-gray-900 font-semibold border-b-2 border-primary-700':
                    selectedTab === tab.value,
                }"
                @click="() => selectTab(tab.value)"
              >
                {{ tab.label }}
              </button>
            </div>
            <div class="absolute border border-gray-200 w-full bottom-0 z-0"></div>
          </div>
          <div class="drawer-section relative mt-9" v-if="selectedTab === 'resume'">
            <div class="mb-4">
              <div
                v-if="['CANCELED'].includes(paymentLink.status) && paymentLink.retailerCancelReason"
                class="mb-4 border rounded-md p-4 bg-red-50 border-red-400 text-red-700"
              >
                <div class="font-semibold mb-3">Pedido cancelado {{ canceledAt }}</div>
                <div>{{ paymentLink.retailerCancelReason }}</div>
              </div>
              <div
                v-else-if="
                  !(
                    ['CANCELED'].includes(paymentLink.status) && paymentLink.retailerCancelReason
                  ) && !!paymentLink.link
                "
                class="relative px-3 overflow-hidden bg-gray-100 rounded-md flex justify-between items-center"
              >
                <div class="whitespace-nowrap overflow-hidden">
                  {{ paymentLink.link }}
                </div>
                <div
                  class="relative text-right top-0 right-0 h-full bg-gray-100 text-gray-500 pr-1"
                >
                  <v-popover placement="left" :offset="8" :open="showTooltip">
                    <button
                      class="button-sm button-icon hover:text-primary-500"
                      @click="copyPaymentLink"
                    >
                      <DuplicateIcon size="24" />
                    </button>

                    <p slot="popover">Link copiado</p>
                  </v-popover>
                </div>
              </div>
            </div>
            <dl class="divide-y divide-gray-200 mb-6">
              <div class="grid grid-cols-3 gap-4 py-4">
                <dt class="text-gray-500">Nº pedido</dt>
                <dd v-if="paymentLink.orderId" class="col-span-2">{{ paymentLink.orderId }}</dd>
                <dd v-else class="col-span-2">-</dd>
              </div>
              <div class="grid grid-cols-3 gap-4 py-4">
                <dt class="text-gray-500">Status</dt>
                <dd class="col-span-2">
                  <span :class="[getPaymentStatusBadge(paymentLink.status)]" class="badge">
                    {{ translatePaymentLinkStatus(paymentLink.status) }}
                  </span>
                </dd>
              </div>
              <div class="grid grid-cols-3 gap-4 py-4">
                <dt class="text-gray-500">Crédito</dt>
                <dd class="col-span-2">
                  <span
                    class="chip"
                    :class="[getCreditApplyStatusChip(paymentLink.creditApplyStatus)]"
                  >
                    <span class="icon-circle"></span>
                    <span>
                      {{ translateCreditApplyStatus(paymentLink.creditApplyStatus) }}
                    </span>
                  </span>
                </dd>
              </div>
              <div class="grid grid-cols-3 gap-4 py-4" v-if="pendency">
                <dt class="text-gray-500">Pendência</dt>
                <dd class="col-span-2">
                  {{ pendency }}
                </dd>
              </div>
              <div class="grid grid-cols-3 gap-4 py-4">
                <dt class="text-gray-500">Criado por</dt>
                <dd class="col-span-2">
                  <div v-if="paymentLink.creatorName">{{ paymentLink.creatorName }}</div>
                  <div class="text-sm text-gray-500">{{ paymentLink.creatorEmail }}</div>
                </dd>
              </div>
              <div class="grid grid-cols-3 gap-4 py-4">
                <dt class="text-gray-500">Criado em</dt>
                <dd class="col-span-2">
                  {{ paymentLink.createdAt | completeDateFilterText }}
                </dd>
              </div>
              <div class="grid grid-cols-3 gap-4 py-4">
                <dt class="text-gray-500">Expira em</dt>
                <dd
                  v-if="
                    !(
                      paymentLink.creditApplyStatus === 'PRE_APPROVED' ||
                      paymentLink.status === 'SENT'
                    )
                  "
                  class="col-span-2"
                >
                  {{ paymentLink.expireAt | completeDateFilterText }}
                </dd>
                <dd v-else class="col-span-2">-</dd>
              </div>
              <div
                v-if="paymentLink && !['CANCELED'].includes(paymentLink.status)"
                class="grid grid-cols-3 gap-4 py-4"
              >
                <dt class="text-gray-500">Potencial de compra</dt>
                <dd class="col-span-2">
                  {{ maxValueApproved | moneyFilter }}
                </dd>
              </div>
            </dl>
            <div class="absolute border-2 border-gray-200 w-full left-0"></div>
            <div class="uppercase tracking-widest text-sm font-semibold pt-6 pb-4">
              Dados do pagamento
            </div>
            <dl class="divide-y divide-gray-200">
              <div class="grid grid-cols-3 gap-4 py-4">
                <dt class="text-gray-500">Nome do cliente</dt>
                <dd class="col-span-2">
                  {{ customerName }}
                </dd>
              </div>
              <div class="grid grid-cols-3 gap-4 py-4" v-if="paymentLink.customerCpf">
                <dt class="text-gray-500">CPF</dt>
                <dd class="col-span-2">
                  {{ hideCPF(paymentLink.customerCpf) }}
                </dd>
              </div>
              <div class="grid grid-cols-3 gap-4 py-4">
                <dt class="text-gray-500">Celular</dt>
                <dd class="col-span-2">
                  {{ hidePhone(paymentLink.customerPhone) }}
                </dd>
              </div>
              <div class="grid grid-cols-3 gap-4 py-4" v-if="paymentLink.customerEmail">
                <dt class="text-gray-500">Email</dt>
                <dd class="col-span-2 break-all">
                  {{ hideEmail(paymentLink.customerEmail) }}
                </dd>
              </div>
              <div class="grid grid-cols-3 gap-4 py-4">
                <dt class="text-gray-500">Descrição do pedido</dt>
                <dd v-if="paymentLink.description" class="col-span-2">
                  {{ paymentLink.description }}
                </dd>
                <dd v-else class="col-span-2">-</dd>
              </div>
              <div class="grid grid-cols-3 gap-4 py-4">
                <dt class="text-gray-500">Valor</dt>
                <dd v-if="paymentLink.value" class="col-span-2">
                  {{ paymentLink.value | moneyFilter }}
                </dd>
                <dd v-else class="col-span-2">-</dd>
              </div>
              <div v-if="isPromotionalRetention" class="grid grid-cols-3 gap-4 py-4">
                <dt class="text-gray-500">Estabelecimento recebe</dt>
                <dd>
                  {{
                    (paymentLink.value *
                      (1 - paymentOptions[0].options[0].installmentOptions[0].retentionPercentage))
                      | moneyFilter
                  }}
                </dd>
              </div>
            </dl>
          </div>
          <div class="drawer-section mt-6" v-else-if="selectedTab === 'options'">
            <PaymentLinkPaymentOptions :paymentOptions="paymentOptions" />
          </div>
          <div class="drawer-section relative mt-6" v-else-if="selectedTab === 'chosen-option'">
            <PaymentLinkChosenPaymentOption :chosenPaymentOption="chosenPaymentOption" />
          </div>
        </div>
      </div>
      <div v-if="paymentLink && !['CANCELED'].includes(paymentLink.status)" class="drawer-footer">
        <div class="flex gap-3 justify-between" v-if="['CONTRACTED'].includes(paymentLink.status)">
          <button
            v-if="isAllowedToCancel"
            class="button text-red-600 hover:bg-red-50 hover:border-red-300"
            @click="cancelTransaction"
          >
            Cancelar pedido
          </button>

          <Button
            v-if="showBilletsDownloadButton"
            :kind="areBilletsDownloading ? 'loading' : 'primary-subtle'"
            class="w-full sm:w-auto"
            @click="downloadBillets"
          >
            <template v-if="areBilletsDownloading">Baixando...</template>
            <template v-else>
              <DownloadIcon size="24" />
              Baixar boletos
            </template>
          </Button>
        </div>

        <div v-else-if="showDeleteConfirmationPaymentLinkButton" class="flex gap-3 justify-end">
          <button class="button" @click="cancelDeletePaymentLink">
            <span>Voltar</span>
          </button>
          <button class="button button-danger-subtle" @click="confirmDeletePaymentLink">
            <span>Confirmar exclusão</span>
          </button>
        </div>

        <div v-else class="flex gap-3 justify-between">
          <button
            class="button text-red-600 hover:bg-red-50 hover:border-red-300"
            @click="showConfirmationDeletePaymentLink"
          >
            <TrashIcon size="24" />
            <span>Excluir link</span>
          </button>
          <button
            class="button button-primary w-full sm:w-auto"
            v-if="
              paymentLink.creditApplyStatus === 'PRE_APPROVED' &&
              paymentLink.status === 'INCOMPLETE' &&
              !paymentLink.value
            "
            @click="goToCreditPaymentLink"
          >
            <PencilAltIcon size="24" />
            <span>Editar link</span>
          </button>
          <button
            class="button button-primary w-full sm:w-auto"
            v-if="
              paymentLink.creditApplyStatus === 'PRE_APPROVED' &&
              paymentLink.status === 'INCOMPLETE' &&
              !!paymentLink.value
            "
            @click="goToCreditPaymentLink"
          >
            <span>Continuar link</span>
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { DuplicateIcon, TrashIcon, DownloadIcon, PencilAltIcon } from '@vue-hero-icons/outline';
import { XIcon } from '@vue-hero-icons/solid';
import { mapActions, mapGetters, mapState } from 'vuex';
import PDFMerger from 'pdf-merger-js/browser';
import utilMixin from '@/mixins/util';
import { Button } from '@/components';
import paymentLinkMixin from '../paymentLink.mixin';
import stringMixin from '../../../mixins/string';
import PaymentLinkPaymentOptions from './PaymentLinkPaymentOptions.vue';
import PaymentLinkChosenPaymentOption from './PaymentLinkChosenPaymentOption.vue';
import PaymentLinkService from '../paymentLink.service';
import { CasaEVideoRetailerIds, LeBiscuitRetailerIds } from '../../../util/util.retailers';

export default {
  name: 'payment-link-summary',
  mixins: [paymentLinkMixin, stringMixin, utilMixin],
  props: {
    paymentLink: {
      type: Object,
    },
  },
  components: {
    TrashIcon,
    PencilAltIcon,
    DuplicateIcon,
    XIcon,
    DownloadIcon,
    PaymentLinkPaymentOptions,
    PaymentLinkChosenPaymentOption,
    Button,
  },
  data: () => ({
    showDeleteConfirmationPaymentLinkButton: false,
    showTooltip: false,
    selectedTab: 'resume',
    areBilletsDownloading: false,
  }),
  computed: {
    ...mapState('paymentLink', [
      'paymentOptions',
      'isDrawerLoading',
      'chosenPaymentOption',
      'maxValueApproved',
    ]),
    ...mapState('retailer', ['currentRetailer']),
    ...mapState('auth', ['hierarchy']),
    ...mapGetters('paymentLink', ['pendency', 'isPromotionalRetention']),
    customerName() {
      const fullName = this.paymentLink.customerName.split(' ');
      return `${fullName[0]} ${fullName[fullName.length - 1]}`;
    },
    canceledAt() {
      if (!this.paymentLink.canceledAt) return '';
      return ` em ${this.paymentLink.canceledAt}`;
    },
    tabs() {
      const tabs = [
        {
          value: 'resume',
          label: 'Resumo',
        },
      ];

      if (this.paymentOptions && this.paymentOptions.length !== 0) {
        tabs.push({
          value: 'options',
          label: 'Parcelamento',
        });
      }

      if (this.chosenPaymentOption) {
        tabs.push({
          value: 'chosen-option',
          label: 'Parcelamento',
        });
      }

      return tabs;
    },
    showBilletsDownloadButton() {
      const { transaction } = this.paymentLink;
      return transaction && transaction.installments && transaction.installments.length > 0;
      // return true;
    },
    isAllowedToCancel() {
      return (
        ![...CasaEVideoRetailerIds, ...LeBiscuitRetailerIds].includes(this.currentRetailer._id)
        || this.hierarchy === 'manager'
      );
    },
  },
  methods: {
    ...mapActions('paymentLink', [
      'deletePaymentLink',
      'setPaymentLinkUserData',
      'setNewPaymentLinkData',
      'getDataForPaymentLinkCreation',
      'resetPaymentLinkCreation',
    ]),
    ...mapActions('loading', ['setIsLoading']),
    ...mapActions('toast', ['showToast']),
    ...mapState('paymentLink', ['paymentLinkUser']),
    hideDrawer() {
      this.$emit('onHideDrawer');
      this.selectedTab = 'resume';
      this.showDeleteConfirmationPaymentLinkButton = false;
    },
    copyPaymentLink() {
      this.showTooltip = true;
      navigator.clipboard.writeText(this.paymentLink.link);
      setTimeout(() => {
        if (this.showTooltip) this.showTooltip = false;
      }, 2000);
    },
    showConfirmationDeletePaymentLink() {
      this.showDeleteConfirmationPaymentLinkButton = true;
    },
    async goToCreditPaymentLink() {
      this.resetPaymentLinkCreation();
      const fields = [
        '_id',
        'customerPhone',
        'customerEmail',
        'checkoutId',
        'customerName',
        'customerCpf',
        'description',
        'status',
        'value',
        'paymentOptions',
      ];
      const values = [
        this.paymentLink._id,
        this.paymentLink.customerPhone,
        this.paymentLink.customerEmail,
        this.paymentLink.checkoutId,
        this.paymentLink.customerName,
        this.paymentLink.customerCpf,
        this.paymentLink.description,
        this.paymentLink.status,
        this.paymentLink.value,
        this.paymentOptions,
      ];
      for (let i = 0; i < fields.length; i += 1) {
        this.setNewPaymentLinkData({ field: fields[i], value: values[i] });
      }

      this.setIsLoading({ isLoading: false, showIcon: 'success', showIconDelay: 1500 });
      if (this.paymentLink.value) {
        await this.$router.replace({
          name: 'review-payment-link',
        });
      } else {
        await this.getDataForPaymentLinkCreation({
          preApprovedCreditId: this.paymentLink.preApprovedCreditId,
          cpf: this.paymentLink.customerCpf,
        });
        await this.$router.replace({
          name: 'create-payment-link',
        });
      }
    },
    cancelDeletePaymentLink() {
      this.showDeleteConfirmationPaymentLinkButton = false;
    },
    async confirmDeletePaymentLink() {
      this.setIsLoading({ isLoading: true, loadingMessage: 'Deletando link' });
      const deletedPaymentLinkId = this.paymentLink._id;
      await this.deletePaymentLink({ paymentLink: this.paymentLink });
      this.cancelDeletePaymentLink();
      this.hideDrawer();
      this.setIsLoading({ isLoading: false });
      this.showToast({
        toastMessage: `Link de pagamento ${deletedPaymentLinkId} excluído.`,
        toastType: 'success',
      });
    },
    cancelTransaction() {
      this.$emit('goToCancelTransactionPage');
    },
    selectTab(tab) {
      this.selectedTab = tab;
    },
    async downloadBillets() {
      try {
        this.areBilletsDownloading = true;
        const { transactionId } = this.paymentLink;
        const merger = new PDFMerger();

        const billetList = await PaymentLinkService.getInstallmentsBilletPDF({ transactionId });
        const promises = billetList.map(async (billet) => {
          await merger.add(Buffer.from(billet));
        });
        await Promise.all(promises);
        await merger.save(`boletos_${transactionId}`);
      } catch (error) {
        console.error('Erro ao baixar boletos');
        console.error(error);
      } finally {
        this.areBilletsDownloading = false;
      }
    },
  },
};
</script>
