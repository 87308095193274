function translatePaymentLinkStatus(paymentLinkStatus) {
  switch (paymentLinkStatus) {
    case 'PENDING':
      return 'Pendente';
    case 'SENT':
      return 'Enviado';
    case 'CONTRACTED':
      return 'Contratado';
    case 'EXPIRED':
      return 'Expirado';
    case 'CANCELED':
      return 'Cancelado';
    case 'INCOMPLETE':
      return 'Incompleto';
    default:
      throw new Error('Invalid payment link status');
  }
}
function getPaymentStatusBadge(paymentLinkStatus) {
  switch (paymentLinkStatus) {
    case 'PENDING':
      return '';
    case 'SENT':
      return 'badge-info';
    case 'CONTRACTED':
      return 'badge-success';
    case 'EXPIRED':
      return 'badge-danger';
    case 'CANCELED':
      return 'badge-danger';
    case 'INCOMPLETE':
      return 'badge-incomplete';
    default:
      throw new Error('Invalid payment link status');
  }
}

function translateCreditApplyStatus(creditApplyStatus) {
  switch (creditApplyStatus) {
    case 'PENDING':
      return 'Pendente';
    case 'PRE_APPROVED':
      return 'Pré-aprovado';
    case 'APPROVED':
      return 'Aprovado';
    case 'DENIED':
      return 'Não aprovado';
    default:
      throw new Error('Invalid payment link status');
  }
}

function getCreditApplyStatusChip(creditApplyStatus) {
  switch (creditApplyStatus) {
    case 'PENDING':
      return 'chip-info';
    case 'PRE_APPROVED':
      return 'chip-warning';
    case 'APPROVED':
      return 'chip-success';
    case 'DENIED':
      return 'chip-danger';
    default:
      throw new Error('Invalid payment link status');
  }
}

export default {
  methods: {
    translatePaymentLinkStatus,
    getPaymentStatusBadge,
    translateCreditApplyStatus,
    getCreditApplyStatusChip,
  },
};
