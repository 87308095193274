<!-- eslint-disable max-len -->
<template>
  <div class="relative h-full">
    <PaymentLinkDrawer :paymentLink="paymentLinkAtDrawer" @onHideDrawer="showDrawer" />

    <sidenav @sidenavOpen="handleSidenavOpen" @onChangeRetailer="handleChangeRetailer" :opened="sidenavOpen" />

    <div class="relative" :class="openConfigDrawer ? 'translate-x-0' : 'translate-x-full'">
      <div class="fixed top-0 left-0 bottom-0 right-0 bg-black transition-all duration-500 z-30"
        :class="!openConfigDrawer ? 'invisible opacity-0' : 'visible opacity-60'"></div>
      <div class="drawer" :class="openConfigDrawer ? 'translate-x-0' : 'translate-x-full'">
        <div class="py-6 flex-grow overflow-x-auto">
          <header class="drawer-header flex items-center justify-between">
            <h3 class="font-bold text-lg">
              Filtros
            </h3>
            <button aria-label="Close" class="button button-plain button-icon hover:border-gray-200 -mr-3"
              @click="showConfigDrawer">
              <XIcon size="24" />
              <span class="sr-only">
                Fechar
              </span>
            </button>
          </header>
          <div class="pt-9 space-y-9">
            <div class="drawer-section">
              <label for="switchToggle" class="switch w-full">
                <!-- label -->
                <div class="flex-grow mr-2">
                  Exibir não aprovados
                </div>
                <!-- toggle -->
                <div class="relative">
                  <!-- input -->
                  <input type="checkbox" v-model="showCreditDeniedFilter" id="switchToggle" class="sr-only">
                  <!-- line -->
                  <div class="switch-knob"></div>
                  <!-- dot -->
                  <div class="switch-dot"></div>
                </div>
              </label>
            </div>
            <div class="drawer-section">
              <h4 class="uppercase tracking-widest text-sm font-semibold mb-5">
                Status
              </h4>
              <ul class="space-y-3">
                <li v-for="(status, index) in statusFilterItems" :key="index" @click="filterHandleStatusSelect(status)">
                  <label class="flex justify-between bg-gray-50 rounded-md py-3 px-4">
                    <span>{{ status.label }}</span>
                    <input
                      type="radio"
                      name="filterStatus"
                      class="radio-button"
                      :checked="status.value === filterStatusSelected.value">
                  </label>
                </li>
              </ul>
            </div>
            <div class="drawer-section">
              <h4 class="uppercase tracking-widest text-sm font-semibold mb-5">
                Criado por
              </h4>
              <ul class="space-y-3">
                <li v-for="(item, index) in creatorFilterItems" :key="index" @click="filterHandleCreatorSelect(item)">
                  <label class="flex justify-between bg-gray-50 rounded-md py-3 px-4">
                    <span>{{ item.label }}</span>
                    <input type="radio" name="filterCreator" class="radio-button"
                      :checked="item.value === filterCreatorSelected.value">
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="drawer-footer">
          <Button kind="primary" class="w-full" @click="applyFilter">
            Aplicar
          </Button>
        </div>
      </div>
    </div>

    <div class="relative lg:ml-64 flex flex-col min-h-full">
      <topbar @sidenavOpen="handleSidenavOpen" pageName="Link de pagamento" />
      <header class="border-b-2 border-gray-200 bg-white">
        <div class="flex flex-col lg:flex-row gap-4 lg:gap-5 px-4 lg:px-6 py-3 lg:pb-5">
          <div class="flex flex-grow gap-3 items-center">
            <div
              class="flex-shrink-0 w-btn h-btn flex items-center justify-center rounded-xl bg-primary-100 text-primary-600"
            >
              <LinkIcon size="24" />
            </div>
            <h1 class="font-bold text-xl">Link de pagamento</h1>
          </div>
          <div class="hidden lg:block flex-shrink-0">
            <Button class="hidden lg:block" v-if="allowedToUsePaymentLink" kind="primary" @click="goToCreditCheck">
              <PlusIcon size="24" />
              <span>Criar link de pagamento</span>
            </Button>
          </div>
        </div>
      </header>
      <div class="flex flex-col py-5 px-4 lg:px-6">
        <div class="flex gap-3 lg:gap-5">
          <div class="flex-grow">
            <div class="w-full lg:w-72">
              <Input class="w-full" @keyup.native.enter="filterBySearch('enter')" @keyup.native="waitTypingFinish"
                v-model="searchText" placeholder="Nome, CPF, nº pedido...">
                <SearchIcon size="24" />
              </Input>
            </div>
          </div>
          <div class="hidden lg:block flex-shrink-0">
            <Button @click="setShowFilters()">
                <AdjustmentsIcon size="24" />
                Filtros
            </Button>
          </div>
          <div class="lg:hidden flex-shrink-0">
            <Button @click="showConfigDrawer">
              <AdjustmentsIcon size="24" />
              <span class="sr-only">Filtros</span>
            </Button>
          </div>
        </div>
        <div v-if="canShowList">
          <div v-show="showFilters" class="flex lg:pt-5 gap-5 flex-shrink-0 items-center">
            <div class="hidden lg:block">
              <Select label="Status" white @select="changeFilterStatus($event)">
                <Option v-for="status in statusFilterItems" :key="status.value" :value="status">
                  {{ status.label }}
                </Option>
              </Select>
            </div>
            <div class="hidden lg:block">
              <Select search label="Criado por" white @select="changeFilterCreator($event)">
                <Option v-for="(creator, index) in creatorFilterItems" :key="index" :value="creator">
                  {{ creator.label }}
                </Option>
              </Select>
            </div>
            <div class="hidden lg:block" @click="showCreditDenied = !showCreditDenied">
              <label for="switchToggle" class="switch">
                <!-- toggle -->
                <div class="relative">
                  <!-- input -->
                  <input type="checkbox" v-model="showCreditDenied" id="switchToggle" class="sr-only">
                  <!-- line -->
                  <div class="switch-knob"></div>
                  <!-- dot -->
                  <div class="switch-dot"></div>
                </div>
                <!-- label -->
                <div class="ml-2">
                  Exibir não aprovados
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <PaymentLinkPopover ref="popover" :show="showPopover" :orderId="popoverOrderId"
        @clickOutside="onClickOutsidePopover" @save="saveNewOrderId" />

      <EmptyMainList v-if="!canShowList" />

      <main v-else class="relative lg:px-6 flex-grow overflow-x-auto">
        <div class="bg-white bg-opacity-90 absolute top-0 right-0 left-0 bottom-0 z-10 flex flex-col items-center pt-36"
          :class="{ 'hidden' : !searchingPaymentLinks }">
          <svg role="status" class="w-12 h-12 mr-2 text-gray-300 animate-spinner" viewBox="0 0 100 101" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"></path>
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="#20627A"></path>
          </svg>
        </div>
        <Table :headers="tableHeaders" v-model="currentSort" @sort="sortByField" class="">
          <template v-if="sortedPaymentLink.length > 0">
            <tr v-for="(paymentLink, index) in sortedPaymentLink" :key="index" class="payment-link-row">
              <td class="whitespace-nowrap flex-col font-semibold w-64">
                <div class="text-gray-900">{{ getPaymentName(paymentLink.customerName) }}</div>
                <div v-show="paymentLink.orderId" ref="orderId" :id="'order' + index"
                  class="payment-link-orderId text-xs text-gray-500 flex align-middle gap-1"
                  @click="showOrderIdPopover(paymentLink, index)">
                  <span class="payment-link-orderId-icon">
                    <PencilAltIcon size="16" id="pencil-edit-orderId" />
                  </span>
                  {{ paymentLink.orderId }}
                </div>
              </td>
              <td>
                <Badge :kind="getPaymentStatusBadge(paymentLink.status)"> {{ translatePaymentLinkStatus(paymentLink.status) }} </Badge>
              </td>
              <td>
                <span class="chip" :class="[getCreditApplyStatusChip(paymentLink.creditApplyStatus)]">
                  <span class="icon-circle"></span>
                  <span>{{ translateCreditApplyStatus(paymentLink.creditApplyStatus) }}</span>
                </span>
              </td>
              <td>
                <div class="text-sm">{{ paymentLink.createdAt | dateFilter }}</div>
                <div class="text-xs text-gray-500">{{ paymentLink.createdAt | hourFilter }}</div>
              </td>
              <td v-if="paymentLink.expireAt">
                <div class="text-sm">{{ paymentLink.expireAt | dateFilter }}</div>
                <div class="text-xs text-gray-500">{{ paymentLink.expireAt | hourFilter }}</div>
              </td>
              <td v-else class="whitespace-nowrap"> - </td>
              <td v-if="paymentLink.value" class="text-right">
                {{ paymentLink.value | moneyFilter }}
              </td>
              <td v-else class="whitespace-nowrap text-right"> - </td>
              <td class="text-right">
                <button v-if="paymentLink.creditApplyStatus === 'PRE_APPROVED'
                  && paymentLink.status === 'INCOMPLETE'"
                  class="button button-plain button-icon text-gray-500 hover:border-gray-200"
                  @click="goToCreditPaymentLink(paymentLink)">
                  <PencilAltIcon size="24" />
                </button>
                <button class="button button-plain button-icon text-gray-500 hover:border-gray-200"
                  @click="showDrawer(paymentLink)">
                  <EyeIcon size="24" />
                </button>
              </td>
            </tr>
          </template>

          <tr v-else>
            <td colspan="100%" class="text-center">
              <div class="py-4">
                Nenhum resultado encontrado
              </div>
            </td>
          </tr>
        </Table>
      </main>

      <footer v-if="canShowList" class="bg-white lg:sticky lg:bottom-0 z-10 lg:mx-6">
        <Pagination
          @pagination="goToSpecificPage"
          :current-page="page"
          :items-per-page="totalAtPage"
          :number-of-items="totalOfPaymentLinks"
        />
      </footer>

      <div v-if="allowedToUsePaymentLink" class="sticky left-0 bottom-0 z-10 border-t lg:hidden bg-white p-4">
        <Button @click="goToCreditCheck" kind="primary" class="w-full">
          <PlusIcon size="24" />
          <span>Criar link de pagamento</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  PlusIcon,
  SearchIcon,
  EyeIcon,
  AdjustmentsIcon,
  XIcon,
  PencilAltIcon,
  LinkIcon,
} from '@vue-hero-icons/outline';
import { createPopper } from '@popperjs/core';
import { Button, Input, Select, Option, Table, Pagination } from '@/components';
import Sidenav from '../../Sidenav.vue';
import Topbar from '../../Topbar.vue';
import stringMixin from '../../../mixins/string';
import paymentLinkMixin from '../paymentLink.mixin';
import PaymentLinkDrawer from '../components/PaymentLinkDrawer.vue';
import EmptyMainList from '../components/PaymentLinkList/EmptyMainList.vue';
import PaymentLinkPopover from '../components/PaymentLinkPopover.vue';
import Badge from '../../../components/Badge.vue';
import { CasaEVideoRetailerIds, LeBiscuitRetailerIds } from '../../../util/util.retailers';

export default {
  name: 'payment-link',
  metaInfo: {
    title: 'Link de pagamento',
  },
  components: {
    XIcon,
    Button,
    AdjustmentsIcon,
    LinkIcon,
    PlusIcon,
    EyeIcon,
    SearchIcon,
    PencilAltIcon,
    Sidenav,
    Topbar,
    EmptyMainList,
    PaymentLinkDrawer,
    PaymentLinkPopover,
    Input,
    Select,
    Option,
    Table,
    Badge,
    Pagination,
  },
  data: () => ({
    canShowList: false,
    openModal: false,
    showFilters: true,
    openDrawer: false,
    paymentLinkAtDrawer: null,
    searchingPaymentLinks: false,
    openConfigDrawer: false,
    sidenavOpen: false,
    showPopover: false,
    popover: null,
    popoverOrderId: null,
    popoverPaymentLinkId: null,
    popoverRowIndex: null,
    maxValueApproved: null,
    filterStatusSelected: {
      label: 'Todos',
      value: '',
    },
    filterCreatorSelected: {
      label: 'Todos',
      value: '',
    },
    // Filters Sections
    showCreditDenied: false,
    showCreditDeniedFilter: false,
    searchText: null,
    currentSearchTimeout: null,
    maxSearchTimeout: 800,
    filterBySearchType: null,
    currentFilterStatus: {
      label: 'Todos',
      value: '',
    },
    statusFilterItems: [
      { label: 'Todos', value: '' },
      { label: 'Enviado', value: 'SENT' },
      { label: 'Contratado', value: 'CONTRACTED' },
      { label: 'Expirado', value: 'EXPIRED' },
      { label: 'Cancelado', value: 'CANCELED' },
      { label: 'Incompleto', value: 'INCOMPLETE' },
    ],
    currentCreatorFilterItem: {
      label: 'Todos',
      value: '',
    },
    creatorFilterItems: [
      {
        label: 'Todos',
        value: '',
      },
    ],

    // Sort Section
    sortInfo: {
      field: 'createdAt',
      type: 'desc',
    },

    // Pagination Section
    limit: 15,
    page: 0,
    maxShowPages: 4,
    maxPages: 0,
    totalAtPage: 0,
    pagination: {
      min: 1,
      max: null,
      pages: [],
    },
    tableHeaders: [
      {
        text: 'Cliente/n° pedido',
      },
      {
        text: 'Status',
        sortable: true,
        sortFunctions: {
          ascending: { status: 1 },
          descending: { status: -1 },
        },
      },
      {
        text: 'Crédito',
        sortable: true,
        sortFunctions: {
          ascending: { creditApplyStatus: 1 },
          descending: { creditApplyStatus: -1 },
        },
        showTooltip: true,
        tooltipText: 'Status da análise de crédito nos últimos 60 dias. Pessoas pré-aprovadas ainda podem ser negadas ao longo das etapas de compra.',
      },
      {
        text: 'Enviado em',
        sortable: true,
        sortFunctions: {
          ascending: { createdAt: 1 },
          descending: { createdAt: -1 },
        },
      },
      {
        text: 'Expira em',
        sortable: true,
        sortFunctions: {
          ascending: { expireAt: 1 },
          descending: { expireAt: -1 },
        },
      },
      {
        text: 'Valor',
        sortable: true,
        sortFunctions: {
          ascending: { value: 1 },
          descending: { value: -1 },
        },
      },
      {
        text: ' ',
      },
    ],
    currentSort: {
      headerIndex: 3,
      direction: 'descending',
    },
  }),
  mixins: [paymentLinkMixin, stringMixin],
  computed: {
    ...mapState('paymentLink', [
      'paymentLinks',
      'paymentOptions',
      'totalOfPaymentLinks',
      'allowedToUsePaymentLink',
      'creators',
    ]),
    ...mapState('retailer', ['currentRetailer']),
    ...mapState('auth', ['userId', 'username', 'name', 'lastname', 'hierarchy']),
    sortedPaymentLink() {
      const { field } = this.sortInfo;
      if (!field) return this.paymentLinks;
      if (this.sortInfo.type === 'asc') {
        return [...this.paymentLinks]
          // eslint-disable-next-line no-nested-ternary
          .sort((a, b) => (((a[field] || '-') > (b[field] || '-')) ? 1 : (((b[field] || '-') > (a[field] || '-')) ? -1 : 0)));
      }

      return [...this.paymentLinks]
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => (((a[field] || '-') > (b[field] || '-')) ? -1 : (((b[field] || '-') > (a[field] || '-')) ? 1 : 0)));
    },
  },
  watch: {
    async showCreditDenied() {
      this.page = 0;
      await this.fillPaymentLinks();
    },
  },
  methods: {
    ...mapActions('paymentLink', [
      'getPaymentLinks',
      'updateOrderId',
      'getPaymentOptions',
      'getPaymentOptionChosen',
      'getOnboardingStep',
      'getCreators',
      'setDrawerLoading',
      'setPaymentOptions',
      'setChosenPaymentOption',
      'setCurrentPaymentLink',
      'getMaxValueApproved',
      'resetMaxValueApproved',
      'setNewPaymentLinkData',
      'getDataForPaymentLinkCreation',
      'resetPaymentLinkCreation',
    ]),
    ...mapActions('loading', ['setIsLoading']),
    showModal() {
      this.openModal = !this.openModal;
    },
    getPaymentName(name) {
      if (!name) return name;
      const fullName = name.split(' ');
      const firstName = fullName[0];
      const lastName = fullName[fullName.length - 1];
      return `${firstName} ${lastName}`;
    },
    async showDrawer(paymentLink) {
      if (!this.openDrawer) {
        this.paymentLinkAtDrawer = paymentLink;
        this.setPaymentOptions(null);
        this.setChosenPaymentOption(null);

        this.setDrawerLoading(true);
        this.setCurrentPaymentLink({ paymentLink });
        await this.getOnboardingStep({ paymentLink });

        const alreadyVerifiedCredit = ['PRE_APPROVED', 'APPROVED'].includes(paymentLink.creditApplyStatus);
        if (alreadyVerifiedCredit) {
          if (paymentLink.transactionId) {
            await this.getPaymentOptionChosen({
              paymentLinkId: paymentLink._id.toString(),
            });
            await this.getMaxValueApproved({
              paymentLinkId: paymentLink._id.toString(),
            });
          } else {
            await this.getPaymentOptions({
              paymentLinkId: paymentLink._id.toString(),
            });
          }
        } else {
          await this.resetMaxValueApproved();
        }

        this.setDrawerLoading(false);
      } else {
        this.paymentLinkAtDrawer = null;
        this.maxValueApproved = null;
      }
      this.openDrawer = !this.openDrawer;
    },
    async goToCreditPaymentLink(paymentLink) {
      this.resetPaymentLinkCreation();
      const alreadyVerifiedCredit = ['PRE_APPROVED', 'APPROVED'].includes(paymentLink.creditApplyStatus);
      if (alreadyVerifiedCredit) {
        await this.getPaymentOptions({
          paymentLinkId: paymentLink._id.toString(),
        });
      }
      const fields = [
        '_id',
        'customerPhone',
        'customerEmail',
        'customerName',
        'checkoutId',
        'customerCpf',
        'description',
        'creditApplyStatus',
        'status',
        'value',
        'paymentOptions',
      ];
      const values = [
        paymentLink._id,
        paymentLink.customerPhone,
        paymentLink.customerEmail,
        paymentLink.customerName,
        paymentLink.checkoutId,
        paymentLink.customerCpf,
        paymentLink.description,
        paymentLink.creditApplyStatus,
        paymentLink.status,
        paymentLink.value,
        this.paymentOptions,
      ];

      for (let i = 0; i < fields.length; i += 1) {
        this.setNewPaymentLinkData({ field: fields[i], value: values[i] });
      }

      this.setIsLoading({ isLoading: false, showIcon: 'success', showIconDelay: 1500 });
      if (paymentLink.value) {
        await this.$router.replace({
          name: 'review-payment-link',
        });
      } else {
        await this.getDataForPaymentLinkCreation({
          preApprovedCreditId: paymentLink.preApprovedCreditId,
          cpf: paymentLink.customerCpf,
        });

        await this.$router.replace({
          name: 'create-payment-link',
        });
      }
    },
    showConfigDrawer() {
      this.showCreditDeniedFilter = this.showCreditDenied;
      this.openConfigDrawer = !this.openConfigDrawer;
      this.filterStatusSelected = this.currentFilterStatus;
      this.filterCreatorSelected = this.currentCreatorFilterItem;
    },
    async applyFilter() {
      this.showCreditDenied = this.showCreditDeniedFilter;
      if (this.currentFilterStatus.value !== this.filterStatusSelected.value) {
        this.currentFilterStatus = this.filterStatusSelected;
      }

      if (this.currentCreatorFilterItem.value !== this.filterCreatorSelected.value) {
        this.currentCreatorFilterItem = this.filterCreatorSelected;
      }

      await this.fillPaymentLinks();

      this.openConfigDrawer = false;
    },
    async filterHandleStatusSelect(newValue) {
      if (newValue === this.filterStatusSelected) return;
      this.filterStatusSelected = newValue;
    },
    async filterHandleCreatorSelect(newValue) {
      if (newValue === this.filterCreatorSelected) return;
      this.filterCreatorSelected = newValue;
    },
    handleSidenavOpen() {
      this.sidenavOpen = !this.sidenavOpen;
    },
    async goToCreditCheck() {
      await this.$router.push({ name: 'credit-verify' });
    },
    canViewAll() {
      return (![...CasaEVideoRetailerIds, ...LeBiscuitRetailerIds]
        .includes(this.currentRetailer._id) || this.hierarchy === 'manager');
    },
    async fillPaymentLinks() {
      this.searchingPaymentLinks = true;
      await this.getPaymentLinks({
        limit: this.limit,
        offset: this.page,
        showCreditDenied: this.showCreditDenied,
        search: this.searchText ? this.searchText.trim() : null,
        status: this.currentFilterStatus.value,
        ctrId: this.canViewAll()
          ? this.currentCreatorFilterItem.value
          : this.userId,
        sortInfo: this.sortInfo,
      });
      this.maxPages = Math.ceil(this.totalOfPaymentLinks / this.limit);
      const paymentsAtPage = this.totalOfPaymentLinks - (this.page) * this.limit;
      this.totalAtPage = paymentsAtPage > this.limit
        ? this.limit
        : paymentsAtPage;
      this.searchingPaymentLinks = false;
    },
    async handleChangeRetailer() {
      await this.fillPaymentLinks();
      this.canShowList = this.allowedToUsePaymentLink && this.paymentLinks.length !== 0;
    },
    async filterBySearch(type) {
      this.filterBySearchType = type;
      await this.fillPaymentLinks();
    },
    waitTypingFinish() {
      if (this.currentSearchTimeout) clearTimeout(this.currentSearchTimeout);

      this.currentSearchTimeout = setTimeout(() => {
        if (this.filterBySearchType === 'enter') {
          this.filterBySearchType = null;
          return;
        }
        this.filterBySearch('timeout');
      }, this.maxSearchTimeout);
    },
    async changeFilterStatus(status) {
      this.currentFilterStatus = status;
      this.page = 0;
      await this.fillPaymentLinks();
    },
    async changeFilterCreator(creator) {
      this.currentCreatorFilterItem = creator;
      this.page = 0;
      await this.fillPaymentLinks();
    },
    async fillCreatorDropdown() {
      if (this.paymentLinks.length === 0) return;
      if (!this.canViewAll()) {
        this.creatorFilterItems.push({
          label: this.name && this.lastname
            ? [this.name, this.lastname].join(' ')
            : this.username,
          value: this.userId,
        });
        return;
      }

      await this.getCreators({ retailerId: this.paymentLinks[0].retailerId });
      this.creators.forEach(({ _id, username, name, lastname }) => {
        this.creatorFilterItems.push({
          label: name && lastname ? [name, lastname].join(' ') : username,
          value: _id,
        });
      });
    },
    onClickOutsidePopover(event) {
      const clickOnEditIcon = event.target.id === 'pencil-edit-orderId';
      if (!this.popover || clickOnEditIcon || this.$refs.orderId.includes(event.target)) return;
      this.showPopover = false;
      this.popover.update();
    },
    showOrderIdPopover(paymentLink, index) {
      this.showPopover = true;
      this.popoverPaymentLinkId = paymentLink._id.toString();
      this.popoverOrderId = paymentLink.orderId;
      this.popoverRowIndex = index;

      this.popover = createPopper(
        this.$refs.orderId[index],
        this.$refs.popover.$el,
        {
          placement: 'top',
          modifiers: [{
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          }],
        },
      );
    },
    async saveNewOrderId(orderId) {
      await this.updateOrderId({
        paymentLinkId: this.popoverPaymentLinkId,
        orderId,
      });
      this.showPopover = false;
      this.popover.update();
    },

    // Sort Section
    async sortByField(field) {
      const { headerIndex, direction } = this.currentSort;
      if (this.sortInfo.field === field) {
        this.sortInfo.type = direction === 'ascending' ? 'asc' : 'desc';
      } else {
        this.sortInfo.type = direction === 'ascending' ? 'asc' : 'desc';
        const fieldToFilter = Object.keys(
          this.tableHeaders[headerIndex].sortFunctions.ascending,
        )[0];
        this.sortInfo.field = fieldToFilter;
      }

      await this.fillPaymentLinks();
    },
    async goToSpecificPage(page) {
      if (this.page === page) return;
      this.page = page;
      await this.fillPaymentLinks();
    },
    setShowFilters() {
      this.showFilters = !this.showFilters;
    },
  },
  async mounted() {
    this.setIsLoading({ isLoading: true });
    await this.fillPaymentLinks();
    await this.fillCreatorDropdown();
    this.canShowList = this.paymentLinks.length !== 0;
    setTimeout(() => {
      this.setIsLoading({ isLoading: false });
    }, 10000);
  },
};
</script>

<style scoped>
.payment-link-orderId-icon {
  display: inline-block;
}

.payment-link-orderId:hover {
  cursor: pointer;
}
</style>
