<!-- eslint-disable max-len -->
<template>
  <main class="relative lg:px-6 flex-grow overflow-x-auto lg:flex lg:flex-col lg:justify-center lg:items-center lg:bg-gray-100">
    <div class="max-w-xl mx-auto p-6 lg:p-0">
      <h2 class="text-2xl font-bold mb-4">
        Realize suas vendas de maneira simples e segura
      </h2>
      <p class="text-gray-500 mb-4">
        O link de pagamento é uma solução que facilita suas vendas, seja na loja física ou online. Basta gerar um link com o valor da venda e compartilhar com seu cliente. Veja como é fácil:
      </p>
      <ol class="ordered-list mb-5">
        <li>
          Informe o valor do produto ou serviço que você quer vender.
        </li>
        <li>
          Envie o link gerado para seu cliente da maneira que você desejar.
        </li>
        <li>
          Aguarde o cliente realizar o pagamento com o Parcelex* e pronto.
        </li>
      </ol>
      <p class="text-sm text-gray-500">
        *Sujeito a aprovação de crédito.
      </p>
    </div>
  </main>
</template>

<script>
export default {
  name: 'empty-main-list',
};
</script>
